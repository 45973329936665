import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import "./Products.css";

const Products = ({ title, homeLabel }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div className="products-container">
      <div className="product-title">
        {products?.length > 0 && (
          <>
            <span>
              <SportsEsportsIcon className="me-2 icon" />
              Recharge
            </span>
            <h2>{title}</h2>
          </>
        )}
        <div className="products">
          {products &&
            products.map((product, index) => {
              return (
                <div
                  onClick={() => navigate(`/product/${product?.name}`)}
                  key={index}
                  className="product text-start"
                >
                  <div
                    className={`product-img-cont loading ${
                      loading && "active"
                    }`}
                  >
                    <img
                      src={`https://voltzonerecharge.in/${product?.image}`}
                      alt=""
                    />
                  </div>
                  <div className="product-name">
                    <p>{product?.name}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Products;
